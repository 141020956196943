export const translationPath = (baseUrl: string, locale: string) =>
  `${baseUrl}assets/locales/wishlist/wishlist_${locale}.json`;

export enum PublicDataKeys {
  ADD_TO_CART = 'gallery_addToCartText',
  LOAD_MORE_BUTTON = 'LOAD_MORE_BUTTON',
  TITLE_TEXT = 'gallery_titleText',
  SUBTITLE_TEXT = 'gallery_subtitleText',
  NO_PRODUCTS_MESSAGE = 'gallery_noProductsMessage',
  EMPTY_STATE_LINK = 'gallery_emptyStateLink',
}

export const enum GQLOperations {
  GetWishlist = 'getWishlist',
  GetAppSettings = 'getAppSettings',
}

export const ORIGIN = 'wishlist';

export const WISHLIST_FEDOPS_APP_NAME = 'wishlist';

export enum GalleryViewMode {
  LIVE_SITE = 0,
  EDITOR_EMPTY_STATE = 1,
  EDITOR_DEMO_STATE = 2,
}

export const enum FedopsEvent {
  RemoveFromWishlist = 'remove-from-wishlist',
}
